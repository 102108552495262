import React, { useEffect } from "react";
import AOS from "aos";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import GetInTouchSection from "../components/GetInTouchSection";

import {
  HeroBackground,
  HeroContainer,
  InternalWrapper,
  HeroPicture,
  TextDiv,
  Title,
  IntroductionDiv,
  GridPanelContainer,
  GridPanel,
  GridPiture,
  GridTextContainer,
  GridTitle,
  GridText,
  CredentialsBackground,
  CredentialsContainer,
  CredentialInfo,
  CredentialTitle,
  CredentialText,
  CredentialPicture,
} from "../styles/pages/company-credentials";

import HeroAvif from "../images/company-credentials/avif/hero.avif";
import HeroJpg from "../images/company-credentials/jpg/hero.jpg";

import HeroMobileAvif from "../images/company-credentials/avif/hero_mobile.avif";
import HeroMobileJpg from "../images/company-credentials/jpg/hero_mobile.jpg";

import accreditedAvif from "../images/company-credentials/avif/accredited.avif";
import accreditedJpg from "../images/company-credentials/jpg/accredited.jpg";
import licensedAvif from "../images/company-credentials/avif/licensed.avif";
import licensedJpg from "../images/company-credentials/jpg/licensed.jpg";

import credentialAvif from "../images/company-credentials/avif/CACH_Logo.avif";
import credentialPng from "../images/company-credentials/png/CACH_Logo.png";

const CompanyCredentials = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <Layout hasTransparentTopBar>
      <Seoinfo
        siteName={"Life Health Group"}
        titlePage={"Our Company Credentials | Life Health Group"}
        description={
          "Life Health Group is a licensed and accredited health care firm that directly employs Certified Home Health Aides."
        }
        keywords={
          "home care, home therapy, durable medical equipment, ride, senior, care services, home, health, nursing, hospice, life health group"
        }
        type={"website"}
        url={"/company-credentials"}
        imageFacebook={"/seo/seo_image.png"}
      />

      <HeroBackground>
        <HeroContainer>
          <HeroPicture>
            <source
              srcset={HeroAvif}
              type="image/avif"
              media="(min-width: 461px)"
            />
            <source
              srcset={HeroMobileAvif}
              type="image/avif"
              media="(max-width: 460px)"
            />
            <source
              srcset={HeroMobileJpg}
              type="image/jpg"
              media="(max-width: 460px)"
            />
            <img
              srcset={HeroJpg}
              alt="A doctor with her patient"
              type="image/jpg"
            />
          </HeroPicture>

          <InternalWrapper>
            <TextDiv data-aos="fade">
              <Title>
                OUR COMPANY <br />
                CREDENTIALS
              </Title>
              <IntroductionDiv>
                Life Home Care is a licensed and accredited health care firm
                that directly employs Certified Home Health Aides that are
                working under the direction of a Registered Nurse. We are
                licensed by the NJ Division of Consumer Affairs and Accredited
                with Distinction by the Commission on Accreditation for Home
                Care.
              </IntroductionDiv>
            </TextDiv>
          </InternalWrapper>
        </HeroContainer>
      </HeroBackground>

      <GridPanelContainer>
        <GridPanel>
          <GridTextContainer>
            <GridTitle>LICENSED HEALTH CARE FIRM</GridTitle>
            <GridText>
              Our health care service firm is an agency type that places or
              arranges for the placement of personnel to provide health care or
              personal care services in the residence of individuals. We are
              registered with the New Jersey Division of Consumer Affairs.
            </GridText>
          </GridTextContainer>
          <GridPiture>
            <source srcset={licensedAvif} type="image/avif" />
            <img
              srcset={licensedJpg}
              alt="A doctor with his patient"
              type="image/jpg"
            />
          </GridPiture>
        </GridPanel>
      </GridPanelContainer>

      <GridPanelContainer>
        <GridPanel>
          <GridPiture>
            <source srcset={accreditedAvif} type="image/avif" />
            <img
              srcset={accreditedJpg}
              alt="A nurse walking with her patient"
              type="image/jpg"
            />
          </GridPiture>
          <GridTextContainer>
            <GridTitle>ACCREDITED BY CAHC</GridTitle>
            <GridText>
              CAHC strives to make the accreditation application process as
              straightforward as possible for our home health care agencies. In
              doing so, we have created an entire library of form samples and
              policy templates that are fully in compliance with State Rules &
              Regulations and make it easier for you to complete the application
              process.
            </GridText>
          </GridTextContainer>
        </GridPanel>
      </GridPanelContainer>

      <CredentialsBackground>
        <CredentialsContainer>
          <CredentialInfo>
            <CredentialTitle>PAYMENT OPTIONS</CredentialTitle>
            <CredentialText>
              We take private pay - Credit card, Check, ACH. <br />
              We accept long-term care insurance. <br />
              We are not a Medicaid provider. <br />
              We are not a Medicare provider. <br />
            </CredentialText>
          </CredentialInfo>
          <CredentialPicture>
            <source srcset={credentialAvif} type="image/avif" />
            <img
              srcset={credentialPng}
              alt="New Jersey C.A.H.C. credential"
              type="image/png"
            />
          </CredentialPicture>
        </CredentialsContainer>
      </CredentialsBackground>
      <GetInTouchSection />
    </Layout>
  );
};

export default CompanyCredentials;
